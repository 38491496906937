import React, { useEffect, useRef, useState } from "react";
import axios, { AxiosError } from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CameraRollOutlinedIcon from "@mui/icons-material/CameraRollOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import EditIcon from "@mui/icons-material/Edit";
import "../styles/EnterLostDisc.css";
import "../globals.css";
import { API_BASE_URL } from "../App";
import {
  Alert,
  Checkbox,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CameraComponent from "./CameraComponent";
import ImageDetectionPopup from "./ImageDetectionPopup";
import { courseAndNavProps } from "./Inventory";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import wordsToNumbers from "words-to-numbers";
import colors from "color-name-list";

interface DiscData {
  course: string;
  name: string;
  disc: string;
  phoneNumber: string;
  bin: string;
  comments: string;
  dateFound: string;
  color: string;
  brand: string;
}
interface VoiceSupportState {
  isListening: boolean;
  activeField: keyof DiscData | "";
}

// Type for the match result
interface MatchResult {
  combination: string;
  match: string;
  score: number;
}

type FuzzballExtractResult = [string, number, number]; // [match, score, index]

declare global {
  interface Window {
    SpeechRecognition: typeof SpeechRecognition;
    webkitSpeechRecognition: typeof SpeechRecognition;
  }

  interface SpeechRecognition extends EventTarget {
    new (): SpeechRecognition;
    continuous: boolean;
    interimResults: boolean;
    onresult: (event: SpeechRecognitionEvent) => void;
    start: () => void;
    stop: () => void;
    abort: () => void;
    // Define other properties and methods as needed
  }

  interface SpeechRecognitionEvent extends Event {
    results: SpeechRecognitionResultList;
    // Define other properties as needed
  }

  interface SpeechRecognitionResultList {
    readonly length: number;
    item(index: number): SpeechRecognitionResult;
    [index: number]: SpeechRecognitionResult;
  }

  interface SpeechRecognitionResult {
    readonly isFinal: boolean;
    [index: number]: SpeechRecognitionAlternative;
  }

  interface SpeechRecognitionAlternative {
    readonly transcript: string;
    // Define other properties as needed
  }
}

interface CustomSpeechRecognitionEvent extends SpeechRecognitionEvent {
  resultIndex: number;
}

function EnterLostDisc(props: courseAndNavProps) {
  const {
    course,
    setSelectedIndex,
    createHandleClose,
    lastIndex,
    setLastIndex,
  } = props;
  const [discData, setDiscData] = useState<DiscData>({
    course: course.courseName,
    name: "",
    disc: "",
    phoneNumber: "",
    bin: "",
    comments: "",
    dateFound: new Date().toISOString().split("T")[0],
    color: "",
    brand: "",
  });

  const [voiceSupport, setVoiceSupport] = useState<VoiceSupportState>({
    isListening: false,
    activeField: "",
  });

  const fuzzball = require("fuzzball");

  const MAX_RETRY_ATTEMPTS = 3; // Maximum number of retry attempts
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showVoice, setShowVoice] = useState(false);
  const [preventFocus, setPreventFocus] = useState(false);
  const [showManual, setShowManual] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [selection, setSelection] = useState("");
  const [side, setSide] = useState<string>("");
  const [frontImage, setFrontImage] = useState<string | null>(null);
  const [backImage, setBackImage] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [apiResponseData, setApiResponseData] = useState<
    Array<{ text: string; category: string }>
  >([]);
  // Define the predefined categories
  const predefinedCategories: string[] = [
    "Select Category",
    "Name",
    "Disc Brand",
    "Disc Name",
    "Phone Number",
    "Color",
    "Comments",
  ];

  const { getToken } = useKindeAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const clearSuccessMessage = () => {
    setShowSuccessMessage(false);
    setSuccessMessage("");
  };

  const clearErrorMessage = () => {
    setShowErrorMessage(false);
    setErrorMessage("");
  };

  const [brandsData, setBrandsData] = useState<string[]>([]);
  const [dbDiscData, setDBDiscData] = useState<string[]>([]);

  async function fetchBrandsAndDiscData() {
    try {
      const accessToken = await getToken();
      const response = await axios.get(
        `${API_BASE_URL}/discs/brands-and-names`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response.data);
      setBrandsData(response.data.brands);
      setDBDiscData(response.data.discMolds);
    } catch (error) {
      console.error("Error fetching brands data:", error);
    }
  }

  useEffect(() => {
    fetchBrandsAndDiscData();
  }, []);

  const findColor = (spokenColor: string, excludeList: string[]) => {
    console.log("Spoken color:", spokenColor);

    if (
      spokenColor.toLowerCase() === "grey" ||
      spokenColor.toLowerCase() === "gray"
    )
      return { name: "Gray", hex: "#808080" };

    if (spokenColor.toLowerCase() === "clear")
      return { name: "Clear", hex: "#FFFFFF" };

    // Normalize the input to lower case and split it into words
    const words = spokenColor.toLowerCase().split(/\s+/);

    // Convert excludeList to lowercase for case-insensitive comparison
    const normalizedExcludeList = excludeList.map((name) => name.toLowerCase());

    // Exclude words that are valid disc or brand names
    const validWords = words.filter(
      (word) => !normalizedExcludeList.includes(word)
    );

    // Try to find a color in the list that matches any of the valid words
    const colorEntry = colors.find((color) =>
      validWords.includes(color.name.toLowerCase())
    );

    console.log("Color Entry:", colorEntry);

    // If a color is found, return its name and hex value, otherwise return a default value or indication
    return colorEntry
      ? { name: colorEntry.name, hex: colorEntry.hex }
      : { name: "unknown", hex: "#FFFFFF" };
  };

  function formatPhoneNumber(phoneNumber: string): string {
    console.log("Phone Number:", phoneNumber);
    // Remove all non-digit characters
    const digits = phoneNumber.replace(/\D/g, "");
    // Check if the number has 10 digits (US number without country code)
    if (digits.length === 10) {
      return `+1${digits}`;
    }
    // Check if the number has 11 digits and starts with '1' (US number with country code)
    if (digits.length === 11 && digits.startsWith("1")) {
      return `+${digits}`;
    }
    console.log("Invalid phone number format:", phoneNumber);
    return phoneNumber; // Return original if it doesn't match the expected format
  }

  function formatName(name: string): string {
    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  // Function to generate all possible contiguous word combinations
  function generateWordCombinations(words: string[]): string[] {
    let combinations: string[] = [];
    for (let i = 0; i < words.length; i++) {
      for (let j = i + 1; j <= words.length; j++) {
        combinations.push(words.slice(i, j).join(" "));
      }
    }
    return combinations;
  }

  // Function to find the best matching brand name
  const findBrand = (
    spokenTranscript: string,
    dbBrandData: string[]
  ): string => {
    const words = spokenTranscript.toLowerCase().split(/\s+/);
    const combinations = generateWordCombinations(words);

    console.log("Brand Data: ", dbBrandData);

    // Use fuzzball to find the best match among all combinations
    const matchResults: MatchResult[] = combinations
      .map((combination): MatchResult | null => {
        const results: FuzzballExtractResult[] = fuzzball.extract(
          combination,
          dbBrandData,
          { limit: 1 }
        );
        return results.length > 0
          ? { combination, match: results[0][0], score: results[0][1] }
          : null;
      })
      .filter((result): result is MatchResult => result !== null);
    console.log("Match Results:", matchResults);

    // Find the highest score among the results
    const bestMatch = matchResults.reduce(
      (best, current) => (current.score > best.score ? current : best),
      { combination: "", match: "", score: 0 }
    );

    console.log("Best Brand Match:", bestMatch);
    return bestMatch.score > 50 ? bestMatch.match : "unknown";
  };

  // Function to find the best matching disc name
  const findDisc = (spokenTranscript: string, dbDiscData: string[]): string => {
    const words = spokenTranscript.toLowerCase().split(/\s+/);
    const combinations = generateWordCombinations(words);

    console.log("Disc Data: ", dbDiscData);

    // Use fuzzball to find the best match among all combinations
    const matchResults: MatchResult[] = combinations
      .map((combination): MatchResult | null => {
        const results: FuzzballExtractResult[] = fuzzball.extract(
          combination,
          dbDiscData,
          { limit: 1 }
        );
        return results.length > 0
          ? { combination, match: results[0][0], score: results[0][1] }
          : null;
      })
      .filter((result): result is MatchResult => result !== null);

    console.log("Match Results:", matchResults);

    // Find the highest score among the results
    const bestMatch = matchResults.reduce(
      (best, current) => (current.score > best.score ? current : best),
      { combination: "", match: "", score: 0 }
    );

    console.log("Best Disc Match:", bestMatch);
    return bestMatch.score > 50 ? bestMatch.match : "unknown";
  };

  const [useBulk, setUseBulk] = useState(false);

  const handleVoiceInput = async (transcript: string, activeField: string) => {
    let processedTranscript = transcript;

    if (
      useBulk &&
      (activeField === "brand" ||
        activeField === "color" ||
        activeField === "disc")
    ) {
      console.log("Bulk input enabled.");
      // Handle bulk input for color, brand, and disc
      if (brandsData.length === 0 || dbDiscData.length === 0) {
        await fetchBrandsAndDiscData(); // Pre-fetch if not already done
      }
      console.log("Transcript:", transcript);

      // First, identify if any words are brands or discs
      const possibleBrand = findBrand(transcript, brandsData);
      const possibleDisc = findDisc(transcript, dbDiscData);

      // Collect all recognized names to exclude from the color search
      const excludeList = [];
      if (possibleBrand) excludeList.push(possibleBrand);
      if (possibleDisc) excludeList.push(possibleDisc);

      console.log("Exclude List:", excludeList);

      // Now check for colors, excluding recognized brand and disc names
      const colorResult = findColor(transcript, excludeList);

      // Update state or continue with logic
      console.log(
        "Brand:",
        possibleBrand,
        "Disc:",
        possibleDisc,
        "Color:",
        colorResult.name
      );
      // const brandMatches = findBrand(transcript, brandsData);
      // console.log("Brand Matches:", brandMatches);

      // const discMatches = findDisc(transcript, dbDiscData);
      // console.log("Disc Matches:", discMatches);

      // Update state with the best matches, if available
      const brandMatch = possibleBrand ? possibleBrand : "";
      const discMatch = possibleDisc ? possibleDisc : "";
      setDiscData((prevData) => ({
        ...prevData,
        color: colorResult.name,
        brand: brandMatch,
        disc: discMatch,
      }));

      // Move to next field or finalize input
      moveToNextField(); // Logic to determine the next field should be adjusted if using bulk input
    } else {
      if (voiceSupport.activeField === "name") {
        processedTranscript = formatName(transcript);
      }

      if (voiceSupport.activeField === "color") {
        const { name } = findColor(transcript, []);
        processedTranscript = name;
      }

      // Specifically process the "bin" field to convert number words to digits
      if (voiceSupport.activeField === "bin") {
        try {
          // Attempt to convert spoken words to numbers
          const numericValue = wordsToNumbers(transcript.trim());
          // Update the transcript only if conversion is successful and numericValue is not NaN
          if (
            !isNaN(Number(numericValue)) &&
            numericValue !== 0 &&
            numericValue !== null
          ) {
            processedTranscript = numericValue.toString();
          }
        } catch (error) {
          console.error("Error converting number words to digits:", error);
          // Handle any errors (e.g., invalid input) gracefully
        }
      }

      if (voiceSupport.activeField === "phoneNumber") {
        console.log("Transcript:", transcript);
        try {
          // Format the numeric string as a phone number
          processedTranscript = formatPhoneNumber(transcript);
          console.log("Processed Transcript:", processedTranscript);
        } catch (error) {
          console.error("Error processing phone number:", error);
        }
      }

      if (voiceSupport.activeField === "brand") {
        // Ensure brandsData is loaded
        if (brandsData.length === 0) {
          await fetchBrandsAndDiscData(); // Optionally handle this elsewhere to avoid fetching here
        }

        console.log("Brands Data:", brandsData);
        console.log("Processed Transcript:", processedTranscript);

        // Perform fuzzy search using fuzzball
        let matches = fuzzball.extract(processedTranscript, brandsData, {
          scorer: fuzzball.ratio,
          cutoff: 50, // Adjust cutoff score as needed
        });

        console.log("Matches:", matches);

        // Use the best match if any matches above the cutoff are found
        if (matches.length > 0 && matches[0][1] > 50) {
          // Assuming matches are sorted by score
          processedTranscript = matches[0][0]; // Update transcript to the best match's text
        } else {
          matches = fuzzball.extract(processedTranscript, brandsData, {
            scorer: fuzzball.ratio,
            cutoff: 30,
          });
        }

        console.log("Matches (Low Cutoff):", matches);
        if (matches.length > 0 && matches[0][1] > 30) {
          processedTranscript = matches[0][0];
        }
      }

      // Ensure disc names are loaded for the "disc" field
      if (voiceSupport.activeField === "disc") {
        if (!dbDiscData) {
          await fetchBrandsAndDiscData(); // Pre-fetch this data ideally
        }

        if (dbDiscData.length === 0) {
          await fetchBrandsAndDiscData(); // Pre-fetch this data ideally
        }

        console.log("DB Disc Data:", dbDiscData);
        console.log("Processed Transcript:", processedTranscript);

        // Perform fuzzy search using fuzzball
        let matches = fuzzball.extract(processedTranscript, dbDiscData, {
          scorer: fuzzball.ratio,
          cutoff: 50, // Adjust cutoff score as needed
        });

        console.log("Matches:", matches);

        // Use the best match if any matches above the cutoff are found
        if (matches.length > 0 && matches[0][1] > 50) {
          processedTranscript = matches[0][0]; // Update transcript to the best match's text
        } else {
          matches = fuzzball.extract(processedTranscript, dbDiscData, {
            scorer: fuzzball.ratio,
            cutoff: 30,
          });
        }

        console.log("Matches (Low Cutoff):", matches);
        if (matches.length > 0 && matches[0][1] > 30) {
          processedTranscript = matches[0][0];
        }
      }

      // Update your state or field value with the processed transcript
      setDiscData((prevData) => ({
        ...prevData,
        [voiceSupport.activeField]: processedTranscript,
      }));
    }
  };

  const handleUseVoiceClick = () => {
    setShowVoice(true);
    setSelection("voice");
    setShowCamera(false);
    setShowManual(false);
    setVoiceSupport({
      ...voiceSupport,
      isListening: true,
      activeField: "name",
    });
    setPreventFocus(true);
  };

  useEffect(() => {
    const handleFocus = (event: Event) => {
      if (preventFocus) {
        event.preventDefault(); // Prevent the focus when voice input is active
        event.stopPropagation(); // Stop the event from bubbling up
        return false; // Return false for good measure
      }
    };

    const inputs = document.querySelectorAll("input");
    const eventTypes: Array<keyof HTMLElementEventMap> = [
      "focus",
      "click",
      "touchstart",
    ];
    inputs.forEach((input) => {
      eventTypes.forEach((type) => {
        input.addEventListener(type, handleFocus, true); // Capture during the capturing phase
      });
    });

    return () => {
      inputs.forEach((input) => {
        eventTypes.forEach((type) => {
          input.removeEventListener(type, handleFocus, true);
        });
      });
    };
  }, [preventFocus]); // Reapply effects when isVoiceActive or activeField changes

  const handleEndVoice = () => {
    // If using SpeechRecognition, stop listening
    if (voiceSupport.isListening) {
      setVoiceSupport({
        ...voiceSupport,
        isListening: false,
        activeField: "",
      });
    }

    setShowVoice(false);
    setShowCamera(false);
    setSelection("manual");
    setShowManual(true);
    setPreventFocus(false);
    console.log("selection: ", selection);
  };

  const moveBack = () => {
    console.log("Moving back 1 field.");
    setPreventFocus(true);

    // Define an array of your field names in the order they should be focused
    const fields: (keyof DiscData)[] = [
      "name",
      "phoneNumber",
      "brand",
      "disc",
      "color",
      "bin",
      "comments",
    ];

    // If activeField is empty, set it to the first field in the array
    const currentField = voiceSupport.activeField || fields[0];

    console.log("Current field:", currentField);

    const currentIndex = fields.indexOf(currentField);
    console.log("Current index:", currentIndex);
    if (currentIndex === 0) return;
    const nextField = fields[currentIndex - 1] || "";
    console.log("Next field:", nextField);
    console.log("Next Field Index: ", fields.indexOf(nextField));

    setVoiceSupport({ isListening: true, activeField: nextField });
    setPreventFocus(false);
    // Optionally focus the next HTML input element
    // document.getElementById(nextField)?.focus();
  };

  // Example function to move to the next field
  const moveToNextField = () => {
    console.log("Moving to the next field.");
    setPreventFocus(true); // Enable the prevention of focus

    // Define an array of your field names in the order they should be focused
    const fields: (keyof DiscData)[] = [
      "name",
      "phoneNumber",
      "brand",
      "disc",
      "color",
      "bin",
      "comments",
    ];

    // If activeField is empty, set it to the first field in the array
    const currentField = voiceSupport.activeField || fields[0];

    console.log("Current field:", currentField);

    const currentIndex = fields.indexOf(currentField);
    console.log("Current index:", currentIndex);

    if (currentIndex === fields.length - 1) {
      handleEndVoice();
      return;
    }

    const nextField = fields[currentIndex + 1] || "";
    console.log("Next field:", nextField);
    console.log("Next Field Index: ", fields.indexOf(nextField));

    setVoiceSupport({ isListening: true, activeField: nextField });
    setPreventFocus(false); // Enable the prevention of focus

    // Optionally focus the next HTML input element
    // document.getElementById(nextField)?.focus();
  };

  useEffect(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true; // Enable live transcription for immediate feedback
      let debounceTimer: string | number | NodeJS.Timeout | undefined;

      recognition.onresult = (event: CustomSpeechRecognitionEvent) => {
        let interimTranscript = "";
        let finalTranscript = "";
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          let transcript = event.results[i].isFinal
            ? event.results[i][0].transcript.trim()
            : event.results[i][0].transcript.trim();
          transcript = transcript.endsWith(".")
            ? transcript.slice(0, -1)
            : transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript;
            console.log("Final Transcript:", finalTranscript);
            handleVoiceInput(finalTranscript, voiceSupport.activeField);
          } else {
            interimTranscript += transcript;
          }
        }

        // Use a debounce to delay processing the interim results
        clearTimeout(debounceTimer);
        const isMobileDevice = window.innerWidth <= 768;
        const timeoutDuration = isMobileDevice ? 500 : 1000; // 500ms for mobile, 1000ms for others

        debounceTimer = setTimeout(() => {
          if (interimTranscript.length > 0) {
            console.log("Interim Transcript to process:", interimTranscript);
            handleVoiceInput(interimTranscript, voiceSupport.activeField);
          }
          moveToNextField();
        }, timeoutDuration); // Adjust the timeout based on testing for responsiveness

        if (finalTranscript.length > 0) {
          clearTimeout(debounceTimer);
          moveToNextField();
        }
      };

      recognition.onspeechend = () => {
        console.log("Speech ended.");
        moveToNextField();
      };

      if (voiceSupport.isListening) {
        recognition.start();
      } else {
        recognition.stop();
        handleEndVoice(); // Ensure handleEndVoice is a function to manage the end of voice recognition
      }

      return () => {
        recognition.abort();
        clearTimeout(debounceTimer);
      };
    }
  }, [voiceSupport.isListening, voiceSupport.activeField]); // React to changes in listening state and active field

  // Define a function to map the incoming category to the predefined categories
  function mapToPredefinedCategory(category: string): string {
    if (predefinedCategories.includes(category)) {
      return category; // Matched a predefined category
    } else {
      return ""; // Default to an empty string
    }
  }

  const handlePlaceholderClick = (side: string) => {
    setSelection("camera");
    if (side === "front") setSide("front");
    else setSide("back");
    setShowCamera(true);
    setShowVoice(false);
    setShowManual(false);
  };

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "phoneNumber") {
      // Remove all non-digit characters except the leading '+'
      let digits = value.replace(/\D/g, "");

      // Ensure the number starts with '1' for US numbers
      if (!digits.startsWith("1")) {
        digits = "1" + digits;
      }

      // Limit to 11 digits (including the leading '1')
      digits = digits.slice(0, 11);

      // Format the digits into E.164 format with +1 prefix
      formattedValue = `+${digits}`;
    }

    // Update the discData state
    setDiscData({ ...discData, [name]: formattedValue });
  };

  const handleUpdateCategory = (index: number, category: string) => {
    const updatedData = [...apiResponseData];
    updatedData[index].category = category;
    setApiResponseData(updatedData);
    console.log("Updated data:", updatedData);
  };

  const handleUpdateText = (index: number, text: string) => {
    const updatedData = [...apiResponseData];
    updatedData[index].text = text;
    setApiResponseData(updatedData);
    console.log("Updated data:", updatedData);
  };

  const handleDeleteRow = (index: number) => {
    const updatedData = [...apiResponseData];
    updatedData.splice(index, 1);
    setApiResponseData(updatedData);
    console.log("Updated data:", updatedData);
  };

  const handleImageCapture = async (
    imageData: string,
    side: string,
    retryCount = 0
  ) => {
    setIsLoading(true); // Set loading to true when the request is initiated
    clearErrorMessage(); // Clear error message
    clearSuccessMessage(); // Clear success message
    console.log(`Captured ${side} image:`, imageData);
    if (side === "front") {
      setFrontImage(imageData);
      setSide("back");
    } else if (side === "back") {
      setBackImage(imageData);
      setSide("front");
    }

    try {
      const accessToken = await getToken();

      const response = await axios.post(`${API_BASE_URL}/detect-text`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        imageBase64: imageData,
      });
      const data = response.data;
      console.log("Data:", data);

      if (!data || data.length === 0) {
        // Handle no text detected
        console.log("No text detected.");
        setErrorMessage("No text detected.");
        setShowErrorMessage(true);
        setIsLoading(false);
        return;
      }

      // Map the categories
      const mappedResponse = data.map(
        (item: { text: any; category: string }) => ({
          text: item.text,
          category: mapToPredefinedCategory(item.category),
        })
      );

      console.log("Mapped response:", mappedResponse);

      setApiResponseData(mappedResponse);
      setIsLoading(false);
      setShowPopup(true); // Show popup when data is received
    } catch (error) {
      console.error("Error processing image:", error);

      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;

        // Check if the error status is 500 and retry if within the maximum retry attempts
        if (
          axiosError.response?.status === 500 &&
          retryCount < MAX_RETRY_ATTEMPTS
        ) {
          console.log(`Retrying API call (Attempt ${retryCount + 1})...`);
          await new Promise<void>((resolve) => setTimeout(resolve, 1000)); // Wait for a moment before retrying
          await handleImageCapture(imageData, side, retryCount + 1);
        } else if (axiosError.response?.status === 204) {
          // Handle no text detected
          console.log("No text detected.");
          setErrorMessage("No text detected.");
          setShowErrorMessage(true);
          setIsLoading(false);
        } else {
          // Handle other errors or reach maximum retry attempts
          console.error("Max retry attempts reached or non-retryable error.");
          setErrorMessage("Error processing image.");
          setShowErrorMessage(true);
          setIsLoading(false);
        }
      }
    }
    setIsLoading(false);
  };

  const prefillForm = () => {
    console.log("Prefilling form with data:", apiResponseData);
    // Iterate through the APIResponseData and map categories to input fields
    apiResponseData.forEach((item) => {
      switch (item.category) {
        case "Disc Brand":
          setDiscData((prevData) => ({
            ...prevData,
            brand: item.text,
          }));
          break;
        case "Comments":
          setDiscData((prevData) => ({
            ...prevData,
            comments: item.text,
          }));
          break;
        case "Phone Number":
          setDiscData((prevData) => ({
            ...prevData,
            phoneNumber: item.text,
          }));
          break;
        case "Name":
          setDiscData((prevData) => ({
            ...prevData,
            name: item.text,
          }));
          break;
        case "Disc Name":
          setDiscData((prevData) => ({
            ...prevData,
            disc: item.text,
          }));
          break;
        case "Color":
          setDiscData((prevData) => ({
            ...prevData,
            color: item.text,
          }));
          break;
        default:
          // If there is no match, do nothing
          break;
      }
    });

    // Close the popup after prefilling the form
    setShowPopup(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    clearErrorMessage(); // Clear error message
    clearSuccessMessage(); // Clear success message
    setIsLoading(true); // Set loading to true when the request is initiated

    const formData = new FormData();
    Object.entries(discData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    if (frontImage) formData.append("frontImage", frontImage);
    if (backImage) formData.append("backImage", backImage);

    const accessToken = await getToken();
    axios
      .post(`${API_BASE_URL}/found-discs`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log("Disc added:", response.data);

        // Set success message with the ID of the row from the DB
        setSuccessMessage(`Disc added with ID ${response.data.id}`);
        setShowSuccessMessage(true);

        // Clear the form and loading state
        setDiscData({
          course: course.courseName,
          name: "",
          disc: "",
          phoneNumber: "",
          bin: discData.bin, //don't overwrite the bin number
          comments: "",
          dateFound: new Date().toISOString().split("T")[0],
          color: "",
          brand: discData.brand, //don't overwrite the brand
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error adding disc:", error);
        setIsLoading(false); // Clear loading state on error
        setErrorMessage("Error adding disc. Please try again.");
        setShowErrorMessage(true);
      });
  };

  const handleCameraButtonClick = () => {
    setSelection("camera");
    setShowCamera(true);
    setShowVoice(false);
    setShowManual(false);
  };

  const handleManualButtonClick = () => {
    setSelection("manual");
    setShowManual(true);
    setShowVoice(false);
    setShowCamera(false);
  };

  const resetForm = (event: React.FormEvent) => {
    event.preventDefault();
    setDiscData({
      course: course.courseName,
      name: "",
      disc: "",
      phoneNumber: "",
      bin: "",
      comments: "",
      dateFound: new Date().toISOString().split("T")[0],
      color: "",
      brand: "",
    });
    setFrontImage(null);
    setBackImage(null);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const form = event.currentTarget.form as HTMLFormElement;
      const index = Array.prototype.indexOf.call(form, event.currentTarget);
      if (index < form.elements.length - 1) {
        const nextElement = form.elements[index + 1] as HTMLInputElement;
        nextElement.focus();
        event.preventDefault();
      }
    }
  };

  const [lastIndexPageName, setLastIndexPageName] = useState<string>("");

  useEffect(() => {
    if (lastIndex === 0) {
      setLastIndexPageName("Enter Lost Disc");
    } else if (lastIndex === 1) {
      setLastIndexPageName("Inventory");
    } else if (lastIndex === 2) {
      setLastIndexPageName("For Sale");
    } else {
      setLastIndexPageName("Error");
    }
  }, [lastIndex]);

  const nameRef = useRef<HTMLInputElement | null>(null);
  const phoneNumberRef = useRef<HTMLInputElement | null>(null);
  const brandRef = useRef<HTMLInputElement | null>(null);
  const discRef = useRef<HTMLInputElement | null>(null);
  const colorRef = useRef<HTMLInputElement | null>(null);
  const binRef = useRef<HTMLInputElement | null>(null);
  const commentsRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    switch (voiceSupport.activeField) {
      case "color":
        if (colorRef.current) {
          colorRef.current.focus();
        }
        break;
      case "phoneNumber":
        if (phoneNumberRef.current) {
          phoneNumberRef.current.focus();
        }
        break;
      case "brand":
        if (brandRef.current) {
          brandRef.current.focus();
        }
        break;
      case "disc":
        if (discRef.current) {
          discRef.current.focus();
        }
        break;
      case "bin":
        if (binRef.current) {
          binRef.current.focus();
        }
        break;
      case "comments":
        if (commentsRef.current) {
          commentsRef.current.focus();
        }
        break;
      case "name":
        if (nameRef.current) {
          nameRef.current.focus();
        }
        break;
      default:
        break;
    }
  }, [voiceSupport.activeField]);

  return (
    <div className="lost-disc-container">
      {lastIndex !== -1 && (
        <div className="row-back">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="back-icon"
            onClick={() => {
              setLastIndex(-1);
              setSelectedIndex(lastIndex);
              createHandleClose();
            }}
          >
            <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"></path>
          </svg>

          <p
            className="lastIndexPageName"
            onClick={() => {
              setLastIndex(-1);
              setSelectedIndex(lastIndex);
              createHandleClose();
            }}
          >
            {lastIndexPageName}
          </p>
        </div>
      )}
      <h1 className="header">{course.courseName} L & F</h1>
      <h1 className="EnterLostDisc">ENTER LOST DISC</h1>
      <div className="button-container">
        <button
          className={
            showCamera ? "button-options-red active" : "button-options-red"
          }
          onClick={handleCameraButtonClick}
        >
          <CameraRollOutlinedIcon className="button-icon-camera" />
          <span className="button-text">
            {isMobile ? "Camera" : "Use Camera"}
          </span>
        </button>
        <button
          className={
            showVoice ? "button-options-blue active" : "button-options-blue"
          }
          onClick={handleUseVoiceClick}
        >
          <MicNoneOutlinedIcon className="button-icon-camera" />
          <span className="button-text">
            {isMobile ? "Voice" : "Use Voice"}
          </span>
        </button>

        <button
          className={
            showManual ? "button-options-black active" : "button-options-black"
          }
          onClick={handleManualButtonClick}
        >
          <CreateOutlinedIcon className="button-icon-pencil" />
          <span className="button-text">
            {isMobile ? "Manual" : "Manual Entry"}
          </span>
        </button>
      </div>

      <div className="use-camera-container">
        {showCamera && (
          <CameraComponent
            onCapture={(imageData, side) => handleImageCapture(imageData, side)}
            side={side}
            setSide={setSide}
            switchToManual={handleManualButtonClick}
            isLoading={isLoading}
          />
        )}

        {selection === "manual" || selection === "voice" ? (
          <form
            onSubmit={handleSubmit}
            style={{ width: "75%", margin: "auto", marginTop: "20px" }}
          >
            <div className="form-group" style={{ position: "relative" }}>
              <TextField
                type="text"
                id="name"
                name="name"
                className="input-field"
                // className={`input-field ${
                //   voiceSupport.activeField === "name" ? "activeField" : ""
                // }`}
                label="Name"
                value={discData.name}
                onChange={handleChange}
                inputRef={nameRef}
                onKeyDown={handleKeyDown}
              />
              {voiceSupport.activeField === "name" &&
                voiceSupport.isListening && (
                  <MicNoneOutlinedIcon className="listeningIcon" />
                )}
            </div>
            <div className="form-group" style={{ position: "relative" }}>
              {/* <TextField
                type="tel" // Use type="tel" to display the numeric keyboard on mobile
                id="phoneNumber"
                name="phoneNumber"
                value={discData.phoneNumber}
                className="input-field"
                // className={`input-field ${
                //   voiceSupport.activeField === "phoneNumber"
                //     ? "activeField"
                //     : ""
                // }`}
                label="Phone Number"
                onChange={handleChange}
                placeholder="xxx-xxx-xxxx"
                inputRef={phoneNumberRef}
              /> */}
              {/* <TextField
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={discData.phoneNumber}
                className="input-field"
                label="Phone Number"
                onChange={handleChange}
                placeholder="xxx-xxx-xxxx"
                inputRef={phoneNumberRef}
                autoComplete="tel"
                inputProps={{
                  pattern: "\\d*",
                  title: "Phone number should contain only digits",
                }}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
                onKeyDown={handleKeyDown}
              /> */}
              <TextField
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={discData.phoneNumber}
                className="input-field"
                label="Phone Number"
                onChange={handleChange}
                placeholder="+1XXXXXXXXXX"
                inputRef={phoneNumberRef}
                autoComplete="tel"
                inputProps={{
                  pattern: "\\+1\\d{10}",
                  title: "Phone number should be in the format +1XXXXXXXXXX",
                }}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
                onKeyDown={handleKeyDown}
              />
              {voiceSupport.activeField === "phoneNumber" &&
                voiceSupport.isListening && (
                  <MicNoneOutlinedIcon className="listeningIcon" />
                )}
            </div>
            <div className="form-group" style={{ position: "relative" }}>
              <TextField
                type="text"
                id="brand"
                name="brand"
                className="input-field"
                // className={`input-field ${
                //   voiceSupport.activeField === "brand" ? "activeField" : ""
                // }`}
                label="Brand"
                value={discData.brand}
                onChange={handleChange}
                inputRef={brandRef}
                onKeyDown={handleKeyDown}
              />
              {voiceSupport.activeField === "brand" &&
                voiceSupport.isListening && (
                  <MicNoneOutlinedIcon className="listeningIcon" />
                )}
            </div>
            <div className="form-group" style={{ position: "relative" }}>
              <TextField
                type="text"
                id="disc"
                name="disc"
                className="input-field"
                // className={`input-field ${
                //   voiceSupport.activeField === "disc" ? "activeField" : ""
                // }`}
                label="Disc"
                value={discData.disc}
                onChange={handleChange}
                inputRef={discRef}
                onKeyDown={handleKeyDown}
              />
              {voiceSupport.activeField === "disc" &&
                voiceSupport.isListening && (
                  <MicNoneOutlinedIcon className="listeningIcon" />
                )}
            </div>
            <div className="form-group" style={{ position: "relative" }}>
              <TextField
                type="text"
                id="color"
                name="color"
                className="input-field"
                // className={`input-field ${
                //   voiceSupport.activeField === "color" ? "activeField" : ""
                // }`}
                label="Color"
                value={discData.color}
                onChange={handleChange}
                inputRef={colorRef}
                onKeyDown={handleKeyDown}
              />
              {voiceSupport.activeField === "color" &&
                voiceSupport.isListening && (
                  <MicNoneOutlinedIcon className="listeningIcon" />
                )}
            </div>
            <div className="form-group" style={{ position: "relative" }}>
              <TextField
                type="number" // Use type="number" to display the numeric keyboard on mobile
                id="bin"
                name="bin"
                className="input-field"
                // className={`input-field ${
                //   voiceSupport.activeField === "bin" ? "activeField" : ""
                // }`}
                label="Bin"
                value={discData.bin}
                onChange={handleChange}
                inputRef={binRef}
                onKeyDown={handleKeyDown}
              />
              {voiceSupport.activeField === "bin" &&
                voiceSupport.isListening && (
                  <MicNoneOutlinedIcon className="listeningIcon" />
                )}
            </div>
            <div
              className="form-group custom-comments"
              style={{ position: "relative" }}
            >
              <TextField
                type="text" // Use type="number" to display the numeric keyboard on mobile
                id="comments"
                name="comments"
                className="input-field"
                // className={`input-field ${
                //   voiceSupport.activeField === "comments" ? "activeField" : ""
                // }`}
                label="Comments"
                value={discData.comments}
                onChange={handleChange}
                inputRef={commentsRef}
                onKeyDown={handleKeyDown}
              />
              {voiceSupport.activeField === "comments" &&
                voiceSupport.isListening && (
                  <MicNoneOutlinedIcon className="listeningIcon" />
                )}
            </div>
            <div className="form-buttons-container">
              <button className="reset-format" onClick={resetForm}>
                Reset Form
              </button>
              <button
                type="submit"
                className={`submit-button ${isLoading ? "loading" : ""}`}
              >
                {isLoading ? <CircularProgress /> : "Submit Disc"}
              </button>
            </div>
          </form>
        ) : (
          <></>
        )}

        {selection === "voice" && (
          <div className="form-navigation-buttons">
            <button
              type="button"
              onClick={moveBack}
              className="back-button"
              disabled={voiceSupport.activeField === "name"}
            >
              Back
            </button>
            <button
              type="button"
              onClick={moveToNextField}
              className="next-button"
              disabled={voiceSupport.activeField === "comments"}
            >
              Next
            </button>
            <button
              type="button"
              onClick={handleEndVoice}
              className="end-button"
            >
              End Voice Session
            </button>
            {/* <Checkbox
              checked={useBulk}
              onChange={(e) => setUseBulk(e.target.checked)}
              name="useBulkOption"
              color="primary"
              style={{ margin: "0px", padding: "0px" }}
            />
            <label htmlFor="useBulkOption" className="bulk-entry-label">
              Use Bulk Entry
            </label> */}
          </div>
        )}

        {selection && (
          <div className="image-placeholder-container">
            {/* Front Image */}
            <div
              className="image-container"
              onClick={() => handlePlaceholderClick("front")}
            >
              {frontImage ? (
                <img src={frontImage} alt="Front" />
              ) : (
                <div className="image-placeholder">
                  <CameraAltIcon className="camera-icon" />
                  {/* <span>Capture</span> */}
                </div>
              )}
            </div>
            <div className="image-placeholder-label">Front</div>

            {/* Back Image */}
            <div
              className="image-container"
              onClick={() => handlePlaceholderClick("back")}
            >
              {backImage ? (
                <img src={backImage} alt="Back" />
              ) : (
                <div className="image-placeholder">
                  <CameraAltIcon className="camera-icon" />
                  {/* <span>Capture Back</span> */}
                </div>
              )}
            </div>
            <div className="image-placeholder-label">Back</div>
          </div>
        )}
      </div>

      {showPopup && (
        <ImageDetectionPopup
          data={apiResponseData}
          onClose={() => setShowPopup(false)}
          onUpdateCategory={handleUpdateCategory}
          onUpdateText={handleUpdateText}
          prefillForm={prefillForm}
          onDelete={handleDeleteRow}
          categories={predefinedCategories}
        />
      )}

      {/* {selection === "camera" && (
        <Typography
          sx={{
            color: "black",
            fontSize: "1.5rem",
            marginBottom: "10px",
          }}
        >
          Use Camera will be available soon. Please check back later.
        </Typography>
      )} */}

      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={4000}
        onClose={clearSuccessMessage}
      >
        <Alert
          onClose={clearSuccessMessage}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={showErrorMessage}
        autoHideDuration={4000}
        onClose={clearErrorMessage}
      >
        <Alert
          onClose={clearErrorMessage}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      {showCamera && (
        <p className="disclaimer-circle ">
          The circle on the screen is an indicator of where the disc should be
          placed but the system will read any text in the frame. To avoid any
          additional text being added please place the disc on a flat surface.
        </p>
      )}
    </div>
  );
}

export default EnterLostDisc;
