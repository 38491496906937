import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import "../styles/LoggedOut.css";
import { useLocation } from "react-router-dom";
import "../globals.css";
import { Box } from "@mui/material";

export default function NoOrg() {
  const { login, register } = useKindeAuth();
  const location = useLocation();

  return (
    <div className="logout-container">
      <p className="oops">Oops!</p>
      <p className="sign-in">Looks like you aren't in an organization.</p>
      <p className="email-support">
        Please have your course admin send an email to
        support@discrescuenetwork.com to get you set up.
      </p>
    </div>
  );
}
