import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import "../styles/LoggedOut.css";
import { useLocation } from "react-router-dom";
import "../globals.css";
import { Box } from "@mui/material";

export default function LoggedOut() {
  const { login, register } = useKindeAuth();
  const location = useLocation();

  return (
    <div className="logout-container">
      <p className="oops">Hang tight!</p>
      <p className="sign-in">Looks like you still need to sign in.</p>

      <Box className="logout-button-container">
        <button
          className="sign-in-button"
          onClick={() =>
            login({
              app_state: {
                redirectTo: location
                  ? location.pathname + location.search
                  : null,
              },
            })
          }
        >
          Sign in to your course
        </button>
        <button className="sign-up-button" onClick={() => register()}>
          Sign up for an account
        </button>
        <a
          className="copywrite"
          href="https://www.discrescuenetwork.com/terms-conditions"
        >
          View our Privacy Policy and Terms of Service
        </a>
      </Box>
      {/* <p className="copywrite">Copyright 2024 Disc Rescue Network LLC</p> */}
    </div>
  );
}
